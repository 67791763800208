<template>
  <v-dialog v-model="dialog" min-width="600" max-width="1200">
    <template v-slot:activator="{on, attrs}">
      <div @click="loadData" v-on="on" v-bind="attrs">
        <slot></slot>
      </div>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Détails de l'équipement</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" icon class="white--text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="pt-4" v-if="loaded">
          <h1 class="primary--text text-center">{{ asset.name }}</h1>
          <v-row class="mt-4" no-gutters>
            <v-col md="6">
              <h2 class="blue--text">
                <v-icon color="blue">mdi-hammer-wrench</v-icon>
                Interventions par année
              </h2>
              <ul class="mt-2">
                <li v-for="(item, index) in stats.intervention_count_by_year" :key="index">
                  <p class="body-1">
                    <span>{{ item.year }} : </span><span>{{ item.count }} intervention(s)</span>
                  </p>
                </li>
              </ul>
            </v-col>
            <v-col md="6">
              <h2 class="primary--text">
                <v-icon color="primary">mdi-currency-eur</v-icon>
                Coût par année
              </h2>
              <ul class="mt-2">
                <li v-for="(item, index) in stats.intervention_cost_by_year" :key="index">
                  <p class="body-1">
                    <span>{{ item.year }} : </span><span
                      v-if="item.total_cost !== null">{{ item.total_cost }} €</span><span v-else>0 €</span>
                  </p>
                </li>
              </ul>
            </v-col>
          </v-row>
          <h2 class="mt-4 text-center font-weight-bold">Détail des interventions</h2>
          <v-data-table :items="stats.intervention_list" :headers="headers">
            <template v-slot:item.date_creation="{item}">
              {{getFormattedDate(item.date_creation)}}
            </template>
            <template v-slot:item.objet="{item}">
              <router-link :to="'/interventions/' + item.id">{{ item.objet }}</router-link>
            </template>
            <template v-slot:item.amount="{item}">
              {{ item.amount }} €
            </template>
            <template v-slot:item.date_intervention="{item}">
              {{getFormattedDate(item.date_intervention)}}
            </template>
          </v-data-table>
          <v-row no-gutters justify="center" align="center">
            <v-btn :to="'/assets/' + asset.id" color="primary"><v-icon>mdi-wrench</v-icon> Voir l'équipement</v-btn>
          </v-row>
        </div>
        <div class="d-flex justify-center align-center" v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";

export default {
  name: 'AssetStats',
  mixins: [DateUtilMixin],
  async mounted() {

  },
  props: {
    asset: Object,
  },
  data() {
    return {
      dialog: false,
      stats: null,
      loaded: false,
      loading: false,
      headers: [
        {value: "date_creation", text: "Date de création"},
        {value: "objet", text: "Demande"},
        {value: "amount", text: "Coût"},
        {value: "status", text: "Statut"},
        {value: "date_intervention", text: "Date de l'intervention"},
      ]
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.stats = await this.$store.dispatch("assets/getAssetStatsById", this.asset);
      this.loaded = true;
      this.loading = false;
    }
  }
}
</script>