<template>
  <div>
    <v-toolbar>
      <v-slide-group>
        <v-slide-item class="mx-2">
          <v-select @change="reloadStats" v-if="intervention_stats !== null" label="Année" item-value="value" item-text="name" v-model="filter.year" :items="active_years"></v-select>
        </v-slide-item>
        <v-slide-item class="mx-2">
          <SiteTreeSelector @change="reloadStats" nomargin v-model="filter.sites"/>
        </v-slide-item>
        <v-slide-item>
          <v-btn color="primary" :disabled="!loaded" @click="printStats">Imprimer</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-card id="stat-interventions">
      <v-row>
        <v-col>
          <apexchart type="bar" height="350" :options="count_interventions_by_state_graph.chartOptions"
                     :series="count_interventions_by_state_graph.series"></apexchart>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <apexchart type="bar" height="350" :options="realise_mensuel_intervention_graph.chartOptions"
                     :series="realise_mensuel_intervention_graph.series"></apexchart>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import dayjs from "dayjs";
import SiteTreeSelector from "@/views/SiteTreeSelector";
/*
let statut_select = {
  "": "Statut inconnu",
  "unknown": "Statut inconnu",
  "waiting": "En attente",
  "unassigned": "Non assigné",
  "assigned": "Assigné",
  "running": "En cours",
  "validation": "A valider",
  "to_note": "A noter",
  "no_cost": "Sans coût",
  "finished": "Réalisée",
}*/

export default {
  name: 'StatInterventions',
  components: {"apexchart": VueApexCharts, SiteTreeSelector},
  async mounted() {
    this.intervention_stats = await this.$store.dispatch("interventions/getInterventionStats", this.filter);
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      intervention_stats: null,
      filter: {
        year: null,
        sites: [],
      }
    };
  },
  computed: {
    count_interventions_by_state() {
      return this.$store.getters["interventions/stats/count_interventions_by_state"];
    },
    count_interventions_by_state_graph() {
      return {
        series: [{
          name: "Nombre d'interventions",
          data: this.intervention_stats?.intervention_by_state.values,
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          title: {
            text: "Répartition actuelle des interventions",
            align: "center",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.intervention_stats?.intervention_by_state.labels,
          },
        }
      }
    },
    realise_mensuel_intervention_graph() {
      return {
        series: [{
          name: 'Dépense ponctuelle',
          data: this.intervention_stats?.realise_per_month.depense_ponctuelle,
        }, {
          name: 'Sous contrat supplémentaire',
          data: this.intervention_stats?.realise_per_month.sous_contrat_supplementaire,
        }, {
          name: 'Casse',
          data: this.intervention_stats?.realise_per_month.casse,
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
          },
          yaxis: {
            title: {
              text: 'Réalisé'
            }
          },
          title: {
            text: "Dépenses réalisées en interventions",
            align: "center",
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " €";
              }
            }
          }
        },
      }
    },
    selected_year: {
      get() {
        return this.$store.getters["finance/stats/getSelectedYear"];
      },
      set(value) {
        return this.$store.commit("finance/stats/setSelectedYear", value);
      }
    },
    active_years() {
      return [{value: null, name: "Toutes périodes confondues"}, ...this.intervention_stats.active_years.map(y => ({name: y, value: y}))];
    },
    sites_select() {
      return [
        {value: null, name: "Tous les sites"},
        ...this.$store.getters["sites/allSites"]
            .map(site => ({"name": this.site_name(site), "value": this.site_name(site)}))
      ];
    },
    selected_site_tree: {
      get() {
        return this.$store.getters["interventions/stats/getSelectedSiteTree"];
      },
      set(value) {
        return this.$store.commit("interventions/stats/setSelectedSiteTree", value)
      }
    },

  },
  methods: {
    site_name(site) {
      let siteObject = site;
      if (siteObject === null || siteObject === undefined) return "Non défini";
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    },
    async printStats() {
      let statNode = document.querySelector("#stat-interventions")
      let canvas = await html2canvas(statNode, {
        scale: 2
      });
      var image = new Image();
      image.src = canvas.toDataURL("image/png");
      const exportPdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width * 0.6, canvas.height * 0.6]
      });
      exportPdf.addImage({imageData: image, x: 0, y: 0});
      exportPdf.save(`interventions${dayjs().format("DD-MM-YYYYTHH_mm")}.pdf`);
    },
    async reloadStats() {
      this.intervention_stats = await this.$store.dispatch("interventions/getInterventionStats", this.filter);
    }
  }
}
</script>